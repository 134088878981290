/* Position and sizing of clickable cross button */
.bm-cross-button {
  top: 7px !important;
  right: 20px !important;
  height: 40px !important;
  width: 40px !important;

  button {
    outline: none !important;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background-color: #baa273 !important;
  height: 37px !important;
  width: 2px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  left: -15px;
}

/* General sidebar styles */
.bm-menu {
  background: #fff;
  // padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding-top: 50px;
}

/* Individual item */
.bm-item {
  display: inline-block;
  width: 100%;
  text-align: center;
  outline: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  font-family: Raleway;
  font-size: 16px;
  font-weight: bold;
  color: #333;

  &:hover {
    color: #baa273;
    text-decoration: none;
  }

  &.active {
    color: #af945e;
  }

  &.languageButtons {
    padding-top: 20px;
    padding-bottom: 50px;

    button {
      border: 0;
      background-color: #fff;
      outline: 0;
      font-family: Montserrat;
      font-size: 14px;
      color: #506350;
      font-weight: normal;
      padding-left: 20px;
      padding-right: 20px;
      cursor: pointer;

      &:hover {
        color: #baa273;
        text-decoration: none;
      }

      &.active {
        color: #af945e;
      }
    }
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
