.modal-backdrop {
  background-color: #000;

  &.show {
    opacity: 0.3;
  }
}

.modal-content {
  border-color: #e9eaec;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.1);

  .modal-header {
    padding: 0.5rem 0.5rem;
    border-color: #eee;

    .close {
      color: #baa273;
      opacity: 1;
      font-weight: 300;
      font-size: 2.5rem;
      outline: none;
      margin-left: 10px;
      line-height: 1;
      padding: 0;
      margin: 0;
      margin-top: -10px;
    }

    .modal-title {
      font-size: 20px;
      font-weight: 500;
      font-family: "Raleway";
      line-height: 1.61;
      color: #555555;
      padding-left: 0.5rem;
    }
  }

  .modal-footer {
    font-family: "Raleway";
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #555555;
    border-top: none;
    padding: 1rem;
    padding-top: 0;
  }

  .galleryLazyImage {
    top: 0;
    left: 0;
    text-align: center;

    img {
      max-width: 100%;
    }
  }

  .imageLoader {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
