.consent-wrap {
  font-size: 12px;
  font-family: Raleway;
  font-weight: 300;
  color: #fff;
  letter-spacing: 0.05rem;
  text-align: left;
  flex: 1 0 200px !important;
  margin: auto 15px auto 0 !important;
}
.privacy-policy-link {
  font-size: inherit;
  color: inherit;
  text-decoration: underline;
  transition: 0.2s ease all;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}
.button-wrapper {
  margin: auto;
}
.consent-button {
  font-size: 14px;
  border-radius: 3px !important;
  background: transparent !important;
  padding: 5px 25px 5px 25px !important;
  border: 1px solid !important;
  color: #fff !important;
  transition: 0.2s ease all;
  margin: 0 !important;
  &:hover {
    background: #fff !important;
    color: #506350 !important;
  }
  &:focus {
    outline: none !important;
  }
}

@media only screen and (max-width: 365px) {
  .consent-button {
    margin: 0.5rem 0 0 0 !important;
  }
}
