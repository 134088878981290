.link {
  cursor: pointer;
  color: #9f5f39;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    color: #6b432c;
  }
}
